
export * from './src/SubscriptionApiGateway'


export class ContentBucketResponse {
    content_bucket_path: string
}


export class ReissuePolicyDocRequest {
    email: string

    constructor(email: string) {
        this.email = email
    }
}

export class ReissuePolicyDocResponse {
    done: boolean
}

        import * as me from '@peachy/subscription-pure'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/codebuild/output/src1272174791/src/codestar-connections.eu-west-2.amazonaws.com/git-http/879656270406/eu-west-2/5886de9c-5c2f-4d33-b265-d0ba742d8141/peachy-punnet/peachy-mono-repo/comp-kit/subscription/pure'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/subscription-pure'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    