import {WebZoneConfig} from './src/config-types/WebZoneConfig'
import {CloudZoneConfig} from './src/config-types/CloudZoneConfig'
import {ZoneInfo} from './src/config-types/ZoneInfo'

export * from './src/config-types/CloudZoneConfig'
export * from './src/config-types/WebZoneConfig'
export * from './src/config-types/ZoneInfo'

export * from './src/get-zone-config'


const zoneTypeMap = {
    WebZoneConfig,
    CloudZoneConfig,
    ZoneInfo
} as const


export type ZoneTypeMap = typeof zoneTypeMap
export type ZoneConfigType = keyof ZoneTypeMap
export type ZoneConfig = InstanceType<ZoneTypeMap[ZoneConfigType]>


        import * as me from '@peachy/zone-config-pure'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/codebuild/output/src1272174791/src/codestar-connections.eu-west-2.amazonaws.com/git-http/879656270406/eu-west-2/5886de9c-5c2f-4d33-b265-d0ba742d8141/peachy-punnet/peachy-mono-repo/zone-config/pure'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/zone-config-pure'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    