import {enumerate, mapWhereDefined, Optional, Redefine} from '@peachy/utility-kit-pure'
import {entries, keys} from 'lodash-es'
import {ClaimStage, ClaimStages, BenefitType, BenefitTypeable} from '../domain/types'
import { benefitTypeOf } from '../domain/utils'

export const Obligations = enumerate(['MANDATORY', 'ENCOURAGED', 'OPTIONAL'] as const)
export type Obligation = keyof typeof Obligations

export type BenefitLimit = Limit<'PENCE' | 'USES'>

export type BenefitUtilisationPolicy = {
    [ClaimStages.COVER_CHECK]?: {
        obligation?: Obligation
    }
    [ClaimStages.CLAIM]?: {
        obligation?: Obligation
        lodgementWindowInWeeks: number
    }
    limit?: BenefitLimit
}
type Limit<Unit extends 'PENCE' | 'USES'> = {
    value: number
    unit: Unit
}

export type CashLimited<Thing extends {limit?: Limit<any>}> = Redefine<Thing, 'limit', Readonly<Limit<'PENCE'>>>
export type UsesLimited<Thing extends {limit?: Limit<any>}> = Redefine<Thing, 'limit', Readonly<Limit<'USES'>>>
export type Limited<Thing> = CashLimited<Thing> | UsesLimited<Thing>

export type BenefitConfigUtilisationPolicy = Redefine<BenefitUtilisationPolicy, 'limit', Pick<BenefitLimit, 'unit'>>
type BenefitConfig = {
    displayName: string
    parentType?: BenefitType
    offerAsUpsell: boolean
    utilisationPolicy?: BenefitConfigUtilisationPolicy
}
type ParentBenefitConfig = {
    displayName: string
    utilisationPolicy?: Omit<BenefitConfigUtilisationPolicy, 'limit'>
}
type ProductsConfig = {
    benefitsByType: Record<BenefitType, BenefitConfig>
    parentBenefitsByType: Record<BenefitType, ParentBenefitConfig>
}

export class ProductConfigService {
    
    constructor(readonly config: ProductsConfig) {}

    getParentTypeOf(benefitType: BenefitType) {
        return this.config.benefitsByType[benefitType].parentType
    }

    getBenefitTypesWithAnyObligationToCoverCheck() {
        return this.getBenefitTypesWithAnyObligationTo(ClaimStages.COVER_CHECK)
    }

    getBenefitTypesWithAnyObligationToClaim() {
        return this.getBenefitTypesWithAnyObligationTo(ClaimStages.CLAIM)
    }

    getUpsellableBenefitTypes() {
        return mapWhereDefined(entries(this.config.benefitsByType), ([benefitType, config]) =>
            config.offerAsUpsell ? benefitType : undefined
        )
    }

    getConfigForBenefit(benefitType: BenefitType) {
        return this.config.benefitsByType[benefitType]
    }

    getConfigForBenefitOrParent(benefitType: BenefitType) {
        return this.getConfigForBenefit(benefitType) ?? this.config.parentBenefitsByType[benefitType]
    }

    getClaimLodgementWindowInWeeksForBenefit(benefitType: BenefitType) {
        return this.getUtilisationPolicyFor<BenefitUtilisationPolicy['CLAIM']>(benefitType, ClaimStages.CLAIM).lodgementWindowInWeeks
    }

    shouldSubmitClaimActivityForAssessment(benefitType: BenefitType, claimStage: ClaimStage) {
        const policy = this.getUtilisationPolicyFor(benefitType, claimStage) ?? this.getParentUtilisationPolicyFor(benefitType, claimStage)
        return ([Obligations.MANDATORY, Obligations.ENCOURAGED] as string[]).includes(policy?.obligation)
    }

    getBenefitDisplayName(thing: BenefitTypeable) {
        return this.getConfigForBenefitOrParent(benefitTypeOf(thing))?.displayName
    }

    private getBenefitTypesWithAnyObligationTo(claimStage: ClaimStage) {
        return mapWhereDefined(keys(this.config.benefitsByType), benefitType =>
            this.getUtilisationPolicyFor(benefitType, claimStage)?.obligation ? benefitType : undefined
        )
    }

    private getUtilisationPolicyFor<ForcedUtilisationPolicyType extends BenefitUtilisationPolicy[ClaimStage]>(benefitType: BenefitType, claimStage: ClaimStage) {
        return this.config.benefitsByType[benefitType]?.utilisationPolicy?.[claimStage] as Optional<ForcedUtilisationPolicyType>
    }

    private getParentUtilisationPolicyFor<ForcedUtilisationPolicyType extends BenefitUtilisationPolicy[ClaimStage]>(benefitType: BenefitType, claimStage: ClaimStage) {
        return this.config.parentBenefitsByType[benefitType]?.utilisationPolicy?.[claimStage] as Optional<ForcedUtilisationPolicyType>
    }

}

