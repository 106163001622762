import { ClaimStages } from '../domain/types'
import { BenefitConfigUtilisationPolicy, Obligations, ProductConfigService } from './ProductConfigService'
import {SovereignPlanConfig} from '@sovereign/product-config'

//todo-dp is this the correct lodgement window?
const mandatoryClaim: Pick<BenefitConfigUtilisationPolicy, 'CLAIM'> = {[ClaimStages.CLAIM]: {obligation: Obligations.MANDATORY, lodgementWindowInWeeks: 16}}


function globalDefinitionParts(type: string) {
    const definition = SovereignPlanConfig.benefits.find(it => it.id === type)
    return {
        displayName: definition.name
    }
}

//todo-dp should we move all the rest of this app / claim specific config into @sovereign/product-config?
//todo-dp is all the app / claim config correct?
export const sovereignProductConfigService = new ProductConfigService({
    benefitsByType: {
        OPTICAL: {
            ...globalDefinitionParts('OPTICAL'),
            offerAsUpsell: false,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim
            }
        },
        DENTAL: {
            ...globalDefinitionParts('DENTAL'),
            offerAsUpsell: false,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim
            }
        },
        PRESCRIPTION: {
            ...globalDefinitionParts('PRESCRIPTION'),
            offerAsUpsell: false,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim
            }
        },
        PHYSIO: {
            ...globalDefinitionParts('PHYSIO'),
            offerAsUpsell: false,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim
            }
        },
        CHIROPODY: {
            ...globalDefinitionParts('CHIROPODY'),
            offerAsUpsell: false,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim
            }
        },
        ACUPUNCTURE: {
            ...globalDefinitionParts('ACUPUNCTURE'),
            offerAsUpsell: false,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim
            }
        },
        HEALTH_SCREENING: {
            ...globalDefinitionParts('HEALTH_SCREENING'),
            offerAsUpsell: false,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim
            }
        },
        CONSULTATIONS_AND_DIAGNOSTICS: {
            //todo-dp fix backend/frontend benefit name missmatch
            ...globalDefinitionParts('CONSULTATIONS_DIAGNOSTICS'),
            offerAsUpsell: false,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim
            }
        },
        HOSPITAL_IN_PATIENT: {
            ...globalDefinitionParts('HOSPITAL_IN_PATIENT'),
            offerAsUpsell: false,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim
            }
        },
        BIRTH_ADOPTION: {
            ...globalDefinitionParts('BIRTH_ADOPTION'),
            offerAsUpsell: false,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim
            }
        },
        GP_SERVICE: {
            ...globalDefinitionParts('GP_SERVICE'),
            offerAsUpsell: false
        },
        SOVEREIGN_PERKS: {
            ...globalDefinitionParts('SOVEREIGN_PERKS'),
            offerAsUpsell: false
        },
        HEALTH_WELLBEING: {
            ...globalDefinitionParts('HEALTH_WELLBEING'),
            offerAsUpsell: false
        },
        EAP: {
            ...globalDefinitionParts('EAP'),
            offerAsUpsell: true
        },
    },
    parentBenefitsByType: {

    }
})
