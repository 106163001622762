
export * from './src/socket-kit'
export * from './src/call-kit'

export * from './src/Switchboard'
export * from './src/Call'
export * from './src/CallCenter'
export * from './src/Duplex'

export function ofType<T>(x: T): T {
    return x
}

        import * as me from '@peachy/sockets-pure'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/codebuild/output/src1272174791/src/codestar-connections.eu-west-2.amazonaws.com/git-http/879656270406/eu-west-2/5886de9c-5c2f-4d33-b265-d0ba742d8141/peachy-punnet/peachy-mono-repo/sockets/pure'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/sockets-pure'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    